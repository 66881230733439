import './App.css';
import HomePage from './components/homePage';

function App() {

  return (
    <div className="App">
      <header className="App-header">
        <HomePage/>
      </header>
      
    </div>
  );
}

export default App;
